<template>
  <div>
    <cabecalho-pagina
      :titulo="$t('modulos.registro_manutencao_troca_pecas.titulos.listagem')"
      :total-registros="tabela.quantidadeItens"
      :titulo-botao-novo="
        $t('modulos.registro_manutencao_troca_pecas.titulos.novo')
      "
      :sem-botao-ok="
        !buscarPermissao('InstrumentoRegistroManutencaoTrocaPeca', 'Inserir')
      "
      :sem-filtro="
        !buscarPermissao('InstrumentoRegistroManutencaoTrocaPeca', 'Listar')
      "
      @abrirNovo="abrirNovo"
      @filtrar="filtrar"
    >
      <template v-slot:filtro="{ filtro }">
        <filtro :filtro="filtro" />
      </template>
    </cabecalho-pagina>
    <botao-padrao
      v-if="
        podeExcluir &&
        buscarPermissao('InstrumentoRegistroManutencaoTrocaPeca', 'Deletar')
      "
      class="my-2"
      outlined
      color="danger"
      @click="excluirRegistros"
    >
      <v-icon>$mdiTrashCanOutline</v-icon>
      {{ $t('geral.botoes.excluir') }}
    </botao-padrao>
    <tabela-padrao-prime-vue
      v-model="tabela.selecionados"
      filtros-com-colunas
      class="mt-2"
      :colunas="tabela.colunas"
      :dados="tabela.dados"
      mostrar-acoes
      selecionar-colunas
      sem-selecionar-todos
      :quantidade-itens="tabela.quantidadeItens"
      :por-pagina="tabela.porPagina"
      :quantidade-paginas="tabela.quantidadePaginas"
      :pagina="tabela.paginaAtual"
      @paginar="listarRegistros"
      @filtrarGlobal="filtrarGlobal"
      @filtroGlobal="filtroGlobal"
      @nova-ordenacao="ordenacaoColuna"
    >
      <template v-slot:acoes="{ slotProps }">
        <dropdown-padrao
          v-if="
            buscarPermissao('InstrumentoRegistroManutencaoTrocaPeca', 'Editar')
          "
          text
          color="secondary"
        >
          <template #botao>
            <v-icon> $dotsVertical </v-icon>
          </template>
          <div>
            <dropdown-padrao-item
              text
              color="secondary"
              @click="abrirEditar(slotProps.data)"
            >
              {{ $t('geral.botoes.editar') }}
            </dropdown-padrao-item>
          </div>
        </dropdown-padrao>
      </template>
    </tabela-padrao-prime-vue>
  </div>
</template>
<script>
import CabecalhoPagina from '@components/layout/CabecalhoPagina.vue';
import RegistroManutencaoTrocaPecaService from '@common/services/cadastros/RegistroManutencaoTrocaPecaService';
import Filtro from './filtro.vue';
import helpers from '@common/utils/helpers';
export default {
  components: {
    CabecalhoPagina,
    Filtro,
  },
  data() {
    return {
      tabela: {
        selecionados: [],
        dados: [],
        colunas: [
          {
            value: 'instrumento.codigoTag',
            text: this.$t(
              'modulos.registro_manutencao_troca_pecas.tabela.instrumento'
            ),
            sortable: true,
          },
          {
            value: 'participanteFornecedor.nome',
            text: this.$t(
              'modulos.registro_manutencao_troca_pecas.tabela.fornecedor'
            ),
            sortable: true,
          },
          {
            value: 'localManutencao',
            text: this.$t(
              'modulos.registro_manutencao_troca_pecas.tabela.local_manutencao'
            ),
            sortable: true,
          },
          {
            value: 'tipoManutencao',
            text: this.$t(
              'modulos.registro_manutencao_troca_pecas.tabela.tipo_manutencao'
            ),
            sortable: true,
          },
          {
            value: 'dataEnvioInstrumento',
            text: this.$t(
              'modulos.registro_manutencao_troca_pecas.tabela.data_envio'
            ),
            sortable: true,
            formatter: (v) => {
              if (v) return helpers.formatarDataBr(v);
            },
          },
        ],
        quantidadeItens: 0,
        quantidadePaginas: 0,
        paginaAtual: 1,
        porPagina: 10,
      },
      filtroAplicado: { filter: '' },
      enumClassificacaoDocumento: [],
      colunaOrdenada: '',
    };
  },
  computed: {
    podeExcluir: function () {
      return this.tabela.selecionados.length > 0;
    },
  },
  created() {
    helpers.redirecionarSemPermissao(
      this,
      'InstrumentoRegistroManutencaoTrocaPeca',
      'Listar'
    );

    const filtros = this.buscarStateFiltros();
    this.filtroAplicado = filtros?.filtroAplicado ?? { filter: '' };
    this.tabela.paginaAtual = filtros?.paginaAtual ?? 1;
    this.tabela.porPagina = filtros?.porPagina ?? 10;
  },
  mounted() {
    this.listarRegistros();
    this.$store.dispatch(
      'Layout/alterarTituloPagina',
      this.$t('modulos.registro_manutencao_troca_pecas.titulos.listagem')
    );
    if(this.$route.params.code){
      this.filtrar()
    }
  },
  methods: {
    buscarPermissao(funcionalidade, funcionalidadeAcao) {
      return helpers.buscarStatePermissoes(
        this,
        funcionalidade,
        funcionalidadeAcao
      );
    },
    filtrar: function (filtro) {
      this.filtroAplicado = { ...filtro, filter: this.filtroAplicado.filter };
      this.listarRegistros();
    },
    listarRegistros: function (
      paginaAtual = this.tabela.paginaAtual,
      porPagina = this.tabela.porPagina
    ) {
      this.tabela.porPagina = porPagina;
      this.salvarStateFiltros({
        filtroAplicado: this.filtroAplicado,
        paginaAtual,
        porPagina,
      });
      let parametros = {
        page: paginaAtual,
        amountPerPage: porPagina,
        instrumentoId: this.$route.params.id,
        ...this.filtroAplicado,
        sort: this.colunaOrdenada,
      };

      if (!this.$route.params.id) delete parametros.instrumentoId;
      this.$store.dispatch('Layout/iniciarCarregamento');
      this.tabela.dados = [];
      RegistroManutencaoTrocaPecaService.listar(parametros)
        .then((res) => {
          this.tabela.dados = res.data.items;

          this.tabela.quantidadeItens = res.data.totalItems;
          this.tabela.quantidadePaginas = res.data.totalPages;
          this.tabela.paginaAtual = paginaAtual;
          this.tabela.porPagina = porPagina;
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    excluirRegistros: function () {
      this.confirmarExcluir().then(() => {
        this.$store.dispatch('Layout/iniciarCarregamento');

        this.tabela.selecionados.forEach((item) => {
          RegistroManutencaoTrocaPecaService.excluir(item.id)
            .then(() => {
              this.listarRegistros();
              this.toastSucesso(
                this.$t(
                  `modulos.registro_manutencao_troca_pecas.exclusao_sucesso`
                )
              );
            })
            .finally(() => {
              this.$store.dispatch('Layout/terminarCarregamento');
              this.tabela.selecionados = [];
            });
        });
      });
    },
    abrirNovo: function () {
      if (this.$route.params.id) {
        this.$router.push({
          name: 'registro-manutencao-troca-pecas-novo-instrumento',
          params: {
            instrumentoId: this.$route.params.id,
            codigo: this.$route.params.codigo,
          },
        });
      } else {
        this.$router.push({ name: 'registro-manutencao-troca-pecas-novo' });
      }
    },
    abrirEditar: function (item) {
      this.$router.push({
        name: 'registro-manutencao-troca-pecas-editar',
        params: { id: item.id },
      });
    },
    voltar() {
      this.$router.push({ name: 'instrumento' });
    },
    buscarStateFiltros() {
      return this.$store.getters['Filtros/filtros'][this.$route.name];
    },
    salvarStateFiltros(dados) {
      this.$store.dispatch('Filtros/adicionarFiltro', {
        rota: this.$route.name,
        dados,
      });
    },
    filtroGlobal(v) {
      this.filtroAplicado.filter = v;
    },
    filtrarGlobal() {
      this.listarRegistros();
    },
    ordenacaoColuna(ordem) {
      this.colunaOrdenada = ordem;
      this.listarRegistros()
    },
  },
};
</script>

<template>
  <div class="row">
    <input-text
      v-model="filtro.instrumento"
      class="col-12 col-md-6"
      :label="
        $t('modulos.registro_manutencao_troca_pecas.formulario.instrumento')
      "
      em-filtro
    />
    <input-text
      v-model="filtro.participante"
      class="col-12 col-md-6"
      :label="
        $t('modulos.registro_manutencao_troca_pecas.formulario.participante')
      "
      em-filtro
    />
    <input-select
      v-model="filtro.localManutencao"
      class="col-12 col-md-6"
      :label="
        $t(
          'modulos.registro_manutencao_troca_pecas.formulario.local_manutencao'
        )
      "
      :placeholder="$t('geral.inputs.selecione')"
      :options="opcoes.localManutencao"
      retornar-objeto
      em-filtro
    />
    <input-select
      v-model="filtro.tipoManutencao"
      class="col-12 col-md-6"
      :label="
        $t('modulos.registro_manutencao_troca_pecas.formulario.tipo_manutencao')
      "
      :placeholder="$t('geral.inputs.selecione')"
      :options="opcoes.tipoManutencao"
      retornar-objeto
      em-filtro
    />
  </div>
</template>
<script>
import EnumeradorService from '@common/services/cadastros/EnumeradorService';
import { EnumModel } from '@common/models/geral/EnumModel';

export default {
  props: {
    filtro: { type: Object, default: () => ({}) },
  },
  data() {
    return {
      opcoes: {
        localManutencao: [],
        tipoManutencao: [],
      },
    };
  },
  mounted() {
    this.listarLocalManutencao();
    this.listarTipoManutencao();
    this.filtro.instrumento = this.$route.params.codigo;
  },
  methods: {
    listarTipoManutencao: function () {
      this.$store.dispatch('Layout/iniciarCarregamento');
      EnumeradorService.buscar('EnumTipoManutencao')
        .then((res) => {
          this.opcoes.tipoManutencao = new EnumModel(res, 'EnumTipoManutencao');
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    listarLocalManutencao: function () {
      this.$store.dispatch('Layout/iniciarCarregamento');
      EnumeradorService.buscar('EnumLocalManutencao')
        .then((res) => {
          this.opcoes.localManutencao = new EnumModel(
            res,
            'EnumLocalManutencao'
          );
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
  },
};
</script>
